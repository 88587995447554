import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import Acknowledgement from "./Acknowledgement";
import RequestForm from "../shared/components/RequestForm";
import { getService } from "../shared/components/restApi";
import AADocument from "../shared/components/AADocument";

function CDPACPAIntakeFormAA() {
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [configValues, setConfigValues] = useState({});
  const [attachmentValues, setAttachmentValues] = useState({});

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    const url = `${process.env.REACT_APP_FRAISER_SRR_CONFIG_CDPA_CPA_AUTHORIZED_AGENT}`;
    let configOptions = {
      method: "GET",
      url,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          console.log("response", response.data);
          if (
            response.data.attachments.items.length > 0 &&
            response.data.attachments.header.length > 0
          ) {
            const {
              items,
              header: title,
              description,
            } = response.data.attachments;
            const { name: docName, linkToPublic: docURL } = items[0];
            setAttachmentValues({
              title,
              docName,
              docURL,
              description,
            });
          }
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});

          const requestTypes = Object.keys(actions).map((request) => {
            switch (request) {
              case "optOut-data-processing":
                return {
                  value: "optOut-data-processing",
                  name: "Right to Opt Out of “Sales”",
                  description:
                    "Right to opt out of the “selling” or “sharing” your personal information with third parties for monetary or other valuable consideration.",
                };

              default:
                return console.warn("Unknown Request Type", request);
            }
          });

          setConfigValues({
            actions,
            requestTypes,
            settings: settingsId,
            _id: settingsId,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  return (
    <div>
      <SrrHeader heading="Fraiser Individual Rights Request Form" />

      <Container className="my-3">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} />
        ) : (
          <>
            <p>
              <strong className="label-text">Authorized Agents only:</strong>
            </p>

            <p>
              If you are an Authorized Agent submitting the privacy request on
              behalf of a consumer residing in Connecticut or Colorado, please
              fill out the form below.
            </p>

            <RequestForm
              configValues={configValues}
              enableLoader={handleLoader}
              getAcknowledgement={handleAcknowledgement}
              getSubmitRequestId={handleSubmitRequestId}
              requestFor="AuthorizedAgentCDPA"
            />
            <AADocument
              requestFor="AuthorizedAgent"
              attachment={attachmentValues}
            />

            <p>
              If you are submitting a request for yourself, please{" "}
              <a href="/">click here</a>.
            </p>

            <p>
              If you are an Authorized Agent submitting the privacy request on
              behalf of a consumer residing in California, please{" "}
              <a href="/CPRA-AuthorizedAgent">click here</a>.
            </p>
          </>
        )}
      </Container>
    </div>
  );
}

export default CDPACPAIntakeFormAA;
