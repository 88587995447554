import React from "react";
import { Navbar, Image } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";

function Header(props) {
  // const [show, setShow] = useState(false);
  // const showDropdown = (e) => {
  //   setShow(!show);
  // };
  // const hideDropdown = (e) => {
  //   setShow(false);
  // };

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.fraiser.org/" target="_blank">
          <Image
            src={Logo}
            alt="Fraiser logo"
            className="p-2"
            width="220px"
            height="auto"
          />
        </Navbar.Brand>
        {/* <div className="flexarea"></div> */}
        {/* <Navbar.Toggle aria-controls="responsive-navbar-nav" /> */}
        {/* <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavDropdown
              title={getText("trustCenter")}
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
              id="collasible-nav-dropdown"
            >
              <NavDropdown.Item
                href="https://www.fraiser.org/privacy.html"
                target="_blank"
              >
                {getText("privacyNotice")}
              </NavDropdown.Item>
              <NavDropdown.Item href="/submit-request">
                {getText("submitRequest")}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default Header;
