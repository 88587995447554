// function to create the paylod with necessary fields based on request
export default function getPayload({
  selectedRequests,
  inputsData,
  configValues,
  recaptchaToken,
}) {
  let primaryEmail = inputsData["email"] ? inputsData["email"] : "";
  let providedFields = [];

  console.log("selectedRequests", selectedRequests);
  selectedRequests &&
    selectedRequests.forEach((request) => {
      for (let key in configValues.actions[request]) {
        if (key === "fields") {
          const dataFields = configValues.actions[request][key]
            .map((field) => {
              switch (field.label) {
                case "Please identify your relationship with Fraiser:":
                  return {
                    value: inputsData["reltionshipWithFraiser"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please identify consumer's relationship with Fraiser:":
                  return {
                    value: inputsData["reltionshipWithFraiser"],
                    label: field.label,
                    _id: field._id,
                  };
                case "First & Last Name":
                  return {
                    value: inputsData["name"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Street Address":
                  return {
                    value: inputsData["address"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Company Name":
                  return {
                    value: inputsData["company"],
                    label: field.label,
                    _id: field._id,
                  };

                case "Apartment/Suite":
                  return {
                    value: inputsData["aptSuite"],
                    label: field.label,
                    _id: field._id,
                  };

                case "Job Title":
                  return {
                    value: inputsData["jobTitle"],
                    label: field.label,
                    _id: field._id,
                  };

                case "City":
                  return {
                    value: inputsData["city"],
                    label: field.label,
                    _id: field._id,
                  };

                case "Phone Number":
                  return {
                    value: inputsData["phoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };

                case "Email":
                  return {
                    value: inputsData["email"],
                    label: field.label,
                    _id: field._id,
                  };

                case "State":
                  return {
                    value: inputsData["state"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Authorized Agent First Name and Last Name":
                  return {
                    value: inputsData["authorizedAgentName"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Authorized Agent Email":
                  return {
                    value: inputsData["email"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Authorized Agent Relationship with Consumer":
                  return {
                    value: inputsData["authorizedAgentRelationshipWithPerson"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Consumer First & Last Name":
                  return {
                    value: inputsData["name"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Consumer City":
                  return {
                    value: inputsData["city"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Consumer Zip Code":
                  return {
                    value: inputsData["zipCode"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Consumer Phone Number":
                  return {
                    value: inputsData["phoneNumber"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Consumer Street Address":
                  return {
                    value: inputsData["address"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Consumer State":
                  return {
                    value: inputsData["state"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please provide specifics about your request":
                  return {
                    value: inputsData["accessRequestDetails"],
                    label: field.label,
                    _id: field._id,
                  };
                case "Please specify what needs to be updated/corrected":
                  return {
                    value: inputsData["updateRequestDetails"],
                    label: field.label,
                    _id: field._id,
                  };

                case "Zip Code":
                  return {
                    value: inputsData["zipCode"],
                    label: field.label,
                    _id: field._id,
                  };

                default:
                  console.warn(`unknown field ${field.label}`);
              }
              return null;
            })
            .filter((field) => field);

          providedFields = [...providedFields, ...dataFields];
        }
      }
    });
  const dataInputs = {
    data: {
      providedFields,
      primaryEmail,
      providedRequests: selectedRequests,
      recaptchaToken,
    },
    meta: {
      id: `${configValues.settings}`,
      pageDataId: `${configValues._id}`,
      type: "dsar",
    },
  };
  return dataInputs;
}
