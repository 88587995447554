import React, { useState, useEffect } from "react";
import { Container, Spinner } from "react-bootstrap";
import SrrHeader from "../shared/components/SrrHeader";
import Acknowledgement from "./Acknowledgement";
import RequestForm from "../shared/components/RequestForm";
import { getService } from "../shared/components/restApi";

function IntakeForm() {
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [authorizedAgentRequest, setAuthorizedAgentRequest] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [configValues, setConfigValues] = useState({});

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setRequestId(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    const url = `${process.env.REACT_APP_FRAISER_SRR_CONFIG_DSAR_INTAKE_MYSELF}`;
    let configOptions = {
      method: "GET",
      url,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});

          let finalOrderedActions;

          // changing the order of actions object
          const objectTemp = {
            access: null,
            portability: null,
            correction: null,
            deletion: null,
            "optOut-data-processing": null,
            optOut: null,
          };

          if (Object.keys(actions).length > 0) {
            finalOrderedActions = Object.assign(objectTemp, actions);
          }

          const requestTypes = Object.keys(finalOrderedActions).map(
            (request) => {
              switch (request) {
                case "access":
                  return {
                    value: "access",
                    name: "Right to Access / Portability",
                    description:
                      "Right to obtain access to the personal information we have collected about you and, where required by law, the right to obtain a copy of the personal information.",
                  };
                case "portability":
                  return {
                    value: "portability",
                    name: "Right to Know",
                    description:
                      "Right to know the categories of personal information we have collected about you in the past 12 months.",
                  };
                case "deletion":
                  return {
                    value: "deletion",
                    name: "Right to Deletion",
                    description:
                      "Right to request the deletion of personal information we have collected from you, subject to certain exceptions.",
                  };
                case "correction":
                  return {
                    value: "correction",
                    name: "Right to Correction",
                    description:
                      "Right to request that any inaccuracies in your personal information be corrected, taking into account the nature of the personal information and the purposes of the processing of your personal information.",
                  };
                case "optOut":
                  return {
                    value: "optOut",
                    name: "Unsubscribe from Marketing Communications",
                    description:
                      "Unsubscribe from marketing communications sent by Fraiser.",
                  };
                case "optOut-data-processing":
                  return {
                    value: "optOut-data-processing",
                    name: "Right to Opt Out of “Sales”",
                    description:
                      "Right to opt out of the “selling” or “sharing” your personal information with third parties for monetary or other valuable consideration.",
                  };

                default:
                  return console.warn("Unknown Request Type", request);
              }
            }
          );

          setConfigValues({
            actions,
            requestTypes,
            settings: settingsId,
            _id: settingsId,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  const handleAuthorizedAgentRequest = (bool) => {
    setAuthorizedAgentRequest(bool);
  };

  return (
    <div>
      <SrrHeader heading="Fraiser Individual Rights Request Form" />

      <Container className="my-3">
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={requestId} />
        ) : (
          <>
            <p>
              In accordance with applicable U.S. privacy law, and depending on
              the jurisdiction in which you reside, you may be able to exercise
              certain data rights regarding your personal information. Please
              complete this form to submit your request. For more information
              about these rights, please see our{" "}
              <a
                href="https://fraiser.org/privacy-notice/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Notice
              </a>
              .
            </p>

            <p>
              In some cases, we may require additional information if we are
              unable to verify your request based on the information you have
              provided. The personal information submitted in connection with
              this request will be used to process your request.
            </p>

            <p>
              Fields marked with an asterisk (
              <span className="text-danger">*</span>) must be completed in order
              to submit this form. Complete the fields below using the
              consumer's information.
            </p>

            <RequestForm
              configValues={configValues}
              enableLoader={handleLoader}
              getAcknowledgement={handleAcknowledgement}
              getSubmitRequestId={handleSubmitRequestId}
              isAuthorizedAgentRequest={handleAuthorizedAgentRequest}
              requestFor="Myself"
            />

            {authorizedAgentRequest ? (
              <>
                <p>
                  If you are an Authorized Agent submitting the privacy request
                  on behalf of a consumer residing in California, please{" "}
                  <a href="/CPRA-AuthorizedAgent">click here</a>.
                </p>

                <p>
                  If you are an Authorized Agent submitting the privacy request
                  on behalf of a consumer residing in Connecticut or Colorado,
                  please <a href="/CDPA-CPA-AuthorizedAgent">click here</a>.
                </p>
              </>
            ) : (
              ""
            )}
          </>
        )}
      </Container>
    </div>
  );
}

export default IntakeForm;
