import React from "react";

function AADocument(props) {
  const { requestFor, attachment } = props;
  const { docName, title, docURL, description } = attachment;
  return (
    <div>
      {requestFor === "AuthorizedAgent" ? (
        <section className="container">
          <h1>{title}</h1>
          <p>{description}</p>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href={docURL && docURL}
              download={docName && docName}
              className="ww-attachment__file"
            >
              {docName && docName}
            </a>
          </div>
        </section>
      ) : (
        ""
      )}
    </div>
  );
}

export default AADocument;
