import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Recaptcha from "./ReCaptcha";
import { useForm, Controller } from "react-hook-form";
import {
  textValidation,
  emailValidation,
  selectValidation,
} from "./Validation";
import getPayload from "./getPayload";
import { getService } from "../../shared/components/restApi";
import { Constants } from "../../shared/components/Constants";
import RequestTypes from "./RequestTypes";

function RequestForm(props) {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const [value, setValue] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [selectedRequests, setSelectedRequests] = useState([]);
  const [accessRequestType, setAccessRequestType] = useState(false);
  const [aaText, setAAText] = useState(false);
  const [updateRequestType, setUpdateRequestType] = useState(false);
  const {
    configValues,
    enableLoader,
    getAcknowledgement,
    getSubmitRequestId,
    isAuthorizedAgentRequest,
    requestFor,
  } = props;

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const { register, handleSubmit, formState, errors, validate, control } =
    useForm({
      mode: "onChange",
    });

  const onSubmit = (data) => {
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      // activate the loader
      enableLoader(true);
      console.log("sendRequestData", sendRequest);
      // payload
      const inputsForPayLoad = {
        selectedRequests,
        inputsData: sendRequest,
        configValues,
        recaptchaToken,
      };

      const payload = getPayload(inputsForPayLoad);
      console.log("payload", payload);

      // API request

      const url = process.env.REACT_APP_CURRENT_ENVIRONMENT_API_URL;

      const submitOptions = {
        method: `POST`,
        url,
        Headers: {
          "Access-Control-Allow-Origin": "*",
        },
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  const getResponseToken = (token) => {
    setRecaptchaToken(token);
  };

  const handleRequestTypesChange = (requestTypes) => {
    setSelectedRequests(requestTypes);
  };

  const handleAccessRequestType = (bool) => {
    setAccessRequestType(bool);
  };

  const handleUpdateRequestType = (bool) => {
    setUpdateRequestType(bool);
  };

  const handleRelationship = (event) => {
    if (event.target.value === "Authorized Agent") {
      setAAText(true);
      isAuthorizedAgentRequest(true);
    } else {
      setAAText(false);
      isAuthorizedAgentRequest(false);
    }
  };

  return (
    <div className="main-content">
      <Card className="my-5 mx-auto form shadow">
        <Card.Body>
          {/* <div className="form-title mb-3">
            <Card.Text>
              <strong>Please provide the following information to start your request:</strong>
            </Card.Text>
            <Card.Text>
              <span className="text-danger">*</span> Required fields
            </Card.Text>
          </div> */}
          <Form className="form-inputs mb-5" onSubmit={handleSubmit(onSubmit)}>
            {requestFor !== "Myself" ? (
              <>
                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Authorized Agent First Name and Last Name{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="authorizedAgentName"
                        className={
                          !errors.authorizedAgentName ? "mb-2" : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.authorizedAgentName && (
                        <small className="text-danger">
                          {" "}
                          {errors.authorizedAgentName.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={1}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        Authorized Agent Relationship with Consumer{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="authorizedAgentRelationshipWithPerson"
                        className={
                          !errors.authorizedAgentRelationshipWithDataSubject
                            ? "mb-2"
                            : "error-border"
                        }
                        ref={register(textValidation)}
                      />
                      {errors.authorizedAgentRelationshipWithDataSubject && (
                        <small className="text-danger">
                          {" "}
                          {
                            errors.authorizedAgentRelationshipWithDataSubject
                              .message
                          }{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>
              </>
            ) : (
              <Form.Group className="mb-3">
                <Row>
                  <Col>
                    <Form.Label className="fw-bold label-text">
                      Please identify your relationship with Fraiser:{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="reltionshipWithFraiser"
                      onChange={handleRelationship}
                      className={
                        !errors.reltionshipWithFraiser ? "mb-2" : "error-border"
                      }
                      ref={register(selectValidation)}
                    >
                      <option value="">Select Relationship</option>
                      {Constants.relationships.map((relation, index) => (
                        <option key={index} value={relation}>
                          {relation}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Form.Group>
            )}

            {aaText ? (
              ""
            ) : (
              <>
                <RequestTypes
                  requestTypes={configValues.requestTypes}
                  onRequestTypesChange={handleRequestTypesChange}
                  onAccessRequestType={handleAccessRequestType}
                  onUpdateRequestType={handleUpdateRequestType}
                />

                {updateRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please specify what needs to be updated/corrected{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="updateRequestDetails"
                          className="mb-2"
                          placeholder="Please do not provide any sensitive personal information through this form"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                {accessRequestType ? (
                  <Form.Group className="mb-3">
                    <Row xs={1}>
                      <Col className="mb-2">
                        <Form.Label className="fw-bold label-text">
                          Please provide specifics about your request{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="accessRequestDetails"
                          className="mb-2"
                          ref={register({ required: true })}
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                ) : (
                  ""
                )}

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}First & Last
                        Name <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        className={!errors.name ? "mb-2" : "error-border"}
                        ref={register(textValidation)}
                      />
                      {errors.name && (
                        <small className="text-danger">
                          {" "}
                          {errors.name.message}{" "}
                        </small>
                      )}
                    </Col>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}Street
                        Address <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="address"
                        className={!errors.address ? "mb-2" : "error-border"}
                        ref={register({ required: true })}
                      />
                      {errors.address && (
                        <small className="text-danger">
                          {" "}
                          {errors.address.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}Company Name
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="company"
                        className={!errors.company ? "mb-2" : "error-border"}
                        ref={register({ required: false })}
                      />
                    </Col>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}
                        Apartment/Suite
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="aptSuite"
                        className={!errors.aptSuite ? "mb-2" : "error-border"}
                        ref={register({ required: false })}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}Job Title
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="jobTitle"
                        className={!errors.jobTitle ? "mb-2" : "error-border"}
                        ref={register({ required: false })}
                      />
                    </Col>
                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}City
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        className={!errors.city ? "mb-2" : "error-border"}
                        ref={register({ required: false })}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {requestFor !== "Myself"
                          ? "Authorized Agent Email"
                          : "Email"}{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        className={!errors.email ? "mb-2" : "error-border mb-2"}
                        ref={register(emailValidation)}
                      />
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </Col>
                    <Col>
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}State{" "}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="state"
                        className={!errors.state ? "mb-2" : "error-border"}
                        ref={register(selectValidation)}
                      >
                        <option value="">Select State</option>
                        {Constants.states[`${requestFor}`].map(
                          (state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          )
                        )}
                      </Form.Control>
                      {errors.state && (
                        <small className="text-danger">
                          {" "}
                          {errors.state.message}{" "}
                        </small>
                      )}
                    </Col>
                  </Row>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Row xs={1} md={2} lg={2}>
                    <Col className="mb-3">
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}Phone Number{" "}
                      </Form.Label>

                      <Controller
                        as={
                          <PhoneInput
                            value={value}
                            country={"us"}
                            onlyCountries={["us"]}
                            onChange={(value) => setValue(value)}
                            inputProps={{
                              name: "phoneNumber",
                              required: false,
                            }}
                            ref={() => {
                              register({ required: false });
                            }}
                            name="phoneNumber"
                            placeholder=""
                            buttonClass="rounded"
                            inputStyle={{ width: "100%" }}
                          />
                        }
                        name="phoneNumber"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: false,
                          minLength: {
                            value: 10,
                            message: "A valid number is required",
                          },
                          maxLength: {
                            value: 12,
                            message: "A valid number is required",
                          },
                        }}
                      />
                      {errors.phoneNumber && (
                        <small className="text-danger">
                          {" "}
                          {errors.phoneNumber.message}{" "}
                        </small>
                      )}
                    </Col>

                    <Col className="mb-2">
                      <Form.Label className="fw-bold label-text">
                        {requestFor === "Myself" ? "" : "Consumer "}Zip Code{" "}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="zipCode"
                        className={!errors.zipCode ? "mb-2" : "error-border"}
                        ref={register({ required: false })}
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <div className="d-flex justify-content-center mt-3">
                  <Recaptcha
                    reCaptchaClick={handleReCaptchaClick}
                    responseToken={getResponseToken}
                  />
                </div>

                <p className="mt-3">
                  <strong>
                    By submitting this request, I am confirming the following:
                  </strong>
                </p>
                <ol>
                  <li>
                    <u>Accuracy</u>: The information I have provided is true and
                    accurate.
                  </li>
                  <li>
                    <u>Privacy</u>: I understand the information will be
                    processed by Fraiser in accordance with our{" "}
                    <a
                      href="https://fraiser.org/privacy-notice/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Notice
                    </a>
                    .
                  </li>
                  <li>
                    <u>Contact</u>: Fraiser has the right to contact me to
                    verify my identity and to process this request.
                  </li>
                </ol>

                <Form.Group>
                  <Row className="d-flex justify-content-center my-5">
                    <Col sm={12} md={10} lg={10} className="text-center">
                      <Button
                        type="submit"
                        className="submit-request"
                        size="lg"
                        style={{
                          width: "inherit",
                        }}
                        disabled={
                          !formState.isValid ||
                          disableSubmit ||
                          validate ||
                          !selectedRequests.length
                        }
                      >
                        Submit Request
                      </Button>
                    </Col>
                  </Row>
                </Form.Group>
              </>
            )}
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

export default RequestForm;
