import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import React from "react";
import Header from "./shared/components/Header";
import Footer from "./shared/components/Footer";
import IntakeForm from "./components/IntakeForm";
import CPRAIntakeFormAA from "./components/CPRAIntakeFormAA";
import CDPACPAIntakeFormAA from "./components/CDPACPAIntakeFormAA";
import "./App.scss";

export const booleanContext = React.createContext();

function App() {
  return (
    <div>
      <booleanContext.Provider value="en">
        <Router>
          <Header />
          <Switch>
            <Route path="/" exact component={IntakeForm} />
            <Route
              path="/CPRA-AuthorizedAgent"
              exact
              component={CPRAIntakeFormAA}
            />
            <Route
              path="/CDPA-CPA-AuthorizedAgent"
              exact
              component={CDPACPAIntakeFormAA}
            />
          </Switch>
          <Footer />
        </Router>
      </booleanContext.Provider>
    </div>
  );
}

export default App;
